<template>
  <div class="collegeBanner">
    <table-list
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="bannerList"
      :options="{ selection: true, operatesWidth: 100, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格标题
const tableTitle = '轮播图列表'
// 顶部按钮
const btns = _this => [
  {
    label: '新增Banner',
    method: _this.handleAddBanner,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '所属板块',
    type: 'select',
    prop: 'mark',
    children: _this.bannerType.map(item => ({ value: item.key, label: item.value })),
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'name',
    label: 'Banner名称',
    minWidth: 180,
  },
  {
    prop: 'imgUrl',
    label: '图片',
    minWidth: 180,
    render: (h, { row }) => {
      return [
        row.imgUrl
          ? h('img', {
              domProps: { src: row.imgUrl },
              style: 'height: 50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'linkUrl',
    label: '链接地址',
    minWidth: 250,
  },
  {
    prop: 'weight',
    label: '序号',
    align: 'right',
    minWidth: 50,
  },
  {
    prop: 'markList',
    label: '所属板块',
    minWidth: 160,
    render: (h, { row }) => {
      return h(
        'ul',
        row.markList.map(v => h('li', v.value)),
      )
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
  {
    label: '删除',
    method: _this.handleDelete,
  },
]
import TableList from '@/components/TableList'
import to from 'await-to'
import { getCollegeBannerList, getCollegeBannerType, getCollegeBannerListDel } from '@/api/college'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      searchForm,
      columns,
      operates,
      btns,
      loading: false,
      bannerList: [],
      bannerType: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
        mark: '',
      },
    }
  },
  created() {
    this.getCollegeBannerTypeData()
    this.getCollegeBannerListData()
  },
  methods: {
    async getCollegeBannerListData() {
      const [res, err] = await to(getCollegeBannerList(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.bannerList = res.data.list
      this.pager.total = res.data.total
    },
    async getCollegeBannerTypeData() {
      const [res, err] = await to(getCollegeBannerType())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.bannerType = res.data
    },
    // 查询按钮
    onSearch(params) {
      this.pager.current = 1
      this.pager.mark = params.mark
      this.getCollegeBannerListData()
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        path: 'editCollegeBanner',
        query: { id: row.id, mark: row.markList[0].key },
      })
    },
    // 删除
    async handleDelete(row) {
      this.$confirm('你确认要删除吗?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [res, err] = await to(getCollegeBannerListDel({ id: row.id }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: res.msg })
          this.getCollegeBannerListData()
        })
        .catch(() => {})
    },
    //添加
    handleAddBanner() {
      this.$router.push('addCollegeBanner')
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCollegeBannerListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCollegeBannerListData()
    },
  },
}
</script>

<style scoped>
.collegeBanner >>> .el-message-box__btns .el-button--primary {
  background-color: #fff !important;
}
</style>
